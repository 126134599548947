html{
  background-color: #333232;
  color: #f7f7f7;
  display: inline;
  height: 100%;
}
:root {
  --bulma-primary: #2e8ead;
  --bulma-size-medium: 0.5rem;
}

h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
table{
  background-color: #5e5e5e;
}

.maint{
  margin: 1em;
}

h2{
  text-align: center;
}

.importb{
  margin: .5em;
}

.startd{
  color: black;
}

.dpicker {
  display: inline-flex;
  border: 0px;
  margin-bottom: 0;
}

.td {
  display:grid;
  justify-content: center;
  border: 0px;
  margin: .5em;
}
.tr {
  background-color: transparent;
}

.dt_table{
  background-color: transparent;
  border: 0px;
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 0;
  max-width: 80vw;
}

table, th, td, tr {
  border: 1px solid #ededed;
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 5px;
  max-width: 80vw;
}
td{
  font-size: 1.15em;
  background-color: #333232;
}
.importbutton{
  min-width: 10vw;
  min-height: 5vh;
  border-radius: 10px;
  background-color: #ededed;
  box-shadow: none !important;
  border-color:#ededed;
}
.importbutton:hover{
  background-color: #5eb7eb;
  cursor: pointer;

}
.checks{
  min-width: 5vw;
  min-height: 5vh;
}
.checks:hover{
  cursor: pointer;
}
.react-datepicker-wrapper input{
  background-color: var(--bulma-info);
  color: black;
  border: 0;
  min-height: 5vh;
  width: 12vw;
  border-radius: 5px;
  display: inline-flex;
  text-align: center;
}
.react-datepicker-wrapper input:hover{
  cursor: pointer;
  background-color: #5eb7eb;
}
.react-datepicker__month-container{
  background-color: #4343;
  color: #a09685 !important;
}
.react-datepicker__header{
background-color: #5c5c5c !important;
  color: #a09685 !important;
}
react-datepicker__navigation react-datepicker__navigation--previous:hover{
  background-color: transparent;
}
react-datepicker__navigation react-datepicker__navigation--next:hover{
  background-color: transparent;
}
.mainTable {
  min-width: 100vw;
  display:inline-grid;
  justify-items: center;
}
.jobTable {
  display:inline-grid;
  justify-items: center;
}
